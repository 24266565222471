<template>
  <div class="error-page">
    <div class="error-header">
      <CLink to="/" class="error-header__title">
        <CIcon name="logo-black" class="error-header__icon" />
      </CLink>
    </div>

    <div class="error-page__content">
      <div class="error">
        <div class="error__content">
          <div class="error__icon-wrapper">
            <CIcon name="error" class="error__icon error__icon--error" />
          </div>

          <div class="error__title">
            <span class="error__font error__font--title">
              {{ $t("general.notFound.title") }}
            </span>
          </div>

          <div class="error__message">
            <div class="error__text">
              <span class="error__font error__font--message">
                {{ $t("general.notFound.message") }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CIcon from "@/features/ui/CIcon.vue";
import CLink from "@/features/ui/CLink.vue";

export default {
  name: "PageError",
  components: {
    CLink,
    CIcon,
  },
};
</script>

<style scoped lang="scss">
.error-page {
  height: 100%;

  &__content {
    display: grid;
    min-height: 100%;
  }
}

.error {
  $parent: &;

  width: 100%;
  align-self: center;
  justify-self: center;

  &__content {
    text-align: center;
    padding: em(32) em(25) em(25);
  }

  &__title {
    text-align: center;
    margin-top: em(23);
  }

  &__message {
    text-align: center;
    margin-top: em(11);
  }

  &__font {
    font-family: $font-default;
    text-align: center;
    font-style: normal;

    &--title {
      color: $black;
      font-size: em(18);
      font-weight: 500;
    }

    &--message {
      color: $app-font-color-gray-3;
      font-size: em(14);
      font-weight: 400;
    }
  }

  &__icon {
    &--error {
      width: em(50);
      height: em(47);
    }
  }
}

.error-header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: em(5) em(15) em(5);
  border-bottom: 1px solid #f3f3f3;

  &__icon {
    width: em(138);
    height: em(46);
  }
}
</style>
